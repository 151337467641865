import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout/Layout";
import {
  ContactUs,
  Error,
  Login,
  SignUP,
  VirtualCards,
  ForgetPassword,
  MyProfile,
  TOS,
  PrivacyPolicy,
} from "./pages";
import { LoginContext } from "./Contexts/loginContext";
import { token } from "./constants/constants";
import { useState, useEffect } from "react";
import ProtectedRoute from "./Contexts/ProtectedRoute";
import { VerifyKyc } from "./components";
import { BackButtonProvider } from "./Contexts/BackButtonContext";



function App() {
  useEffect(() => {
    const changeTopBarColor = (color) => {
      let metaTag = document.querySelector('meta[name="theme-color"]');
      if (!metaTag) {
        metaTag = document.createElement("meta");
        metaTag.name = "theme-color";
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute("content", color);
    };

    changeTopBarColor("#FFFFFF"); // White color
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: (
            <BackButtonProvider>
              <VirtualCards />
            </BackButtonProvider>
          ),
        },
        { path: "contact", element: <ContactUs /> },
        {
          path: "virtual-cards",
          element: (
            <BackButtonProvider>
              <VirtualCards />
            </BackButtonProvider>
          ),
        },
        {
          path: "virtual-cards/verify",
          element: <ProtectedRoute element={<VerifyKyc />} />,
        },
        {
          path: "verify",
          element: <ProtectedRoute element={<VerifyKyc />} />,
        },
        {
          path: "my-profile",
          element: <ProtectedRoute element={<MyProfile />} />,
        },
      ],
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-of-service",
      element: <TOS />,
    },
    { path: "/login", element: <Login /> },
    { path: "/sign-up", element: <SignUP /> },
    { path: "/forget-password", element: <ForgetPassword /> },
  ]);

  const [user, setUser] = useState({});

  return (
    <LoginContext.Provider value={{ user, setUser, token }}>
      <RouterProvider router={router} />
    </LoginContext.Provider>
  );
}

export default App;
