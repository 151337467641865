import { useNavigate } from "react-router-dom";
// import cardImage from "../assets/promoo.png";  // Use default import for PromoImage
import PromoImage from "../assets/yeh.png";  // Use default import for PromoImage
import { secondaryColor } from "../constants/constants";
import HorizontalScroll from "../components/HorizontalScroll";
// import { useEffect, useState } from "react"; 

export const VirtualCardLanding = () => {
  const navigate = useNavigate();
  // const [isPC, setIsPC] = useState(false);
  // const [deferredPrompt, setDeferredPrompt] = useState(null); // Store the deferred install prompt
  // const [ setShowPromo] = useState(true); // State for promotional pop-up visibility

  // useEffect(() => {
  //   const checkDeviceType = () => {
  //     if (window.innerWidth > 600) {
  //       setIsPC(true); // It's a PC
  //     } else {
  //       setIsPC(false); // It's not a PC (mobile/tablet)
  //     }
  //   };

  //   checkDeviceType(); // Initial check
  //   window.addEventListener("resize", checkDeviceType); // Add resize event listener to check on resize

  //   const installPromptEvent = (e) => {
  //     e.preventDefault(); // Prevent the default install prompt from showing automatically
  //     setDeferredPrompt(e); // Save the event to trigger later
  //   };

  //   // Listen for the "beforeinstallprompt" event (Chrome/Edge)
  //   window.addEventListener("beforeinstallprompt", installPromptEvent);

  //   // Auto-close the promotional pop-up after 5 seconds
  //   // const autoClosePromo = setTimeout(() => {
  //   //   setShowPromo(false);
  //   //   if (deferredPrompt) {
  //   //     // Show the native A2HS prompt
  //   //     deferredPrompt.prompt();
  //   //     deferredPrompt.userChoice.then((choiceResult) => {
  //   //       if (choiceResult.outcome === "accepted") {
  //   //         console.log("User accepted the A2HS prompt");
  //   //       } else {
  //   //         console.log("User dismissed the A2HS prompt");
  //   //       }
  //   //       setDeferredPrompt(null); // Reset the deferred prompt after user decision
  //   //     });
  //   //   }
  //   // }, 500000000); // 5 seconds delay to auto-close the promo pop-up

  //   return () => {
  //     window.removeEventListener("resize", checkDeviceType);
  //     window.removeEventListener("beforeinstallprompt", installPromptEvent);
  //     // clearTimeout(autoClosePromo); // Cleanup the timer on component unmount
  //   };
  // }, [deferredPrompt]);

  // if (isPC) {
  //   return (
  //     <div className="w-full h-screen flex items-center justify-center bg-white">
  //       <div className="bg-yellow-100 text-black text-center py-4 px-6 text-xl sm:text-2xl font-medium">
  //         ⚠️ PC version is currently under maintenance. Please use a mobile device.
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-white">
      {/* <div className="w-full bg-red-100 text-black text-center py-2 text-sm sm:text-md font-medium">
        ⚠️ Some services may be temporarily unavailable up to Jan 05, 2025.
      </div> */}

      {/* Promotional Pop-Up */}
      {/* {showPromo && (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-60">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-4/5 md:w-2/5">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              🎉 New Android and IOS App is Ready!
            </h3>
            <p className="text-gray-600 mb-4">
            For a better experience, install our app now and enjoy an enhanced experience.
            </p>
            <img
              src={cardImage}
              alt="Special Promotion"
              className="mb-4 w-full h-auto rounded-md"
            />
            <button
              onClick={() => setShowPromo(false)} // Close pop-up manually
              className="mt-4 text-sm text-gray-600 underline"
            >
             Got It
            </button>
          </div>
        </div>
      )} */}

      {/* Main Content */}
      <div className="w-[90%] md:w-[75%] flex flex-col items-center gap-8 text-center mt-4">
        <div className="flex flex-col gap-4">
          <h1 className="text-[8vw] sm:text-5xl md:text-6xl font-bold text-black leading-tight">
            VIRTUAL CARDS
          </h1>
          <p className="text-md sm:text-lg md:text-xl text-gray-600">
            Get all the benefits while spending online, in-store, and abroad. Save on currency conversion fees, with extra layer of security from Yehacard.
          </p>
        </div>

        <button
          onClick={() => navigate("/login")}
          className={`bg-[${secondaryColor}] hover:bg-[${secondaryColor}] text-white font-medium py-3 px-8 rounded-full shadow-md transition-all duration-300`}
        >
          Order your card now
        </button>

        {/* Promo Image as Landing Image */}
        <div className="w-full flex justify-center mt-0">
          <img src={PromoImage} alt="Virtual Cards" className="w-full max-w-md md:max-w-lg" />
        </div>

        <div className="w-full mt-0">
          <HorizontalScroll />
        </div>
      </div>

      <div className="w-full bg-gray-100 py-4 mt-8">
        <div className="w-[90%] md:w-[75%] mx-auto flex justify-center items-center text-gray-500 text-sm">
          &copy; 2024 Yeha Card. All rights reserved.
        </div>
      </div>
    </div>
  );
};
